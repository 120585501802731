module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SocialWeaver, Inc.","short_name":"SocialWeaver","description":"Scale your marketing reach by directly engaging your employees, partners, and influencers.","start_url":"/?source=pwa","scope":"/","background_color":"#F2F2F2","theme_color":"#1772E8","display":"minimal-ui","icon":"static/assets/logo/socialweaver.png","icon_options":{"purpose":"any maskable"},"orientation":"any","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a72fbc352a40926c6dee5d3b423ea9f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
